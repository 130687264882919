import { AddCircle, DeleteOutlined } from '@mui/icons-material'
import { Box, IconButton, Stack, Tooltip } from '@mui/material'
import { useEffect } from 'react'
import type { FieldPath, FieldValues, Path } from 'react-hook-form'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import type { TextFieldControllerProps } from '@/components/inputs/TextFieldController'

import PhoneNumberFieldController from './PhoneNumberFieldController'

export type PhoneNumberListFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = {
  name: TName
  isSmsEnabled: boolean
  maxPhoneCount?: number
  TextFieldControllerProps?: Omit<TextFieldControllerProps<TFieldValues, TName>, 'name'>
}

export function PhoneNumberListFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  maxPhoneCount,
  isSmsEnabled,
  TextFieldControllerProps,
}: Readonly<PhoneNumberListFieldControllerProps<TFieldValues, TName>>) {
  const { t } = useTranslation()
  const { clearErrors, watch } = useFormContext<TFieldValues>()
  const { fields, append, remove } = useFieldArray({
    name,
    rules: {
      maxLength: maxPhoneCount,
    },
  })

  const phoneNumbers = watch(name)
  const isEmptyList = !phoneNumbers?.length

  async function handleAddNewPhoneClick() {
    append('' as TFieldValues[typeof name][0])
  }

  // This way we ensure that at least one phone number is always present in the list when SMS is enabled
  useEffect(() => {
    if (!fields.length) {
      append('' as TFieldValues[typeof name][0])
    }
  }, [fields])

  return (
    <Stack gap={3}>
      <Stack gap={5}>
        {fields.map((field, index) => (
          <Stack key={field.id} gap={1} sx={{ position: 'relative' }}>
            <Stack alignItems="flex-start" direction="row">
              <PhoneNumberFieldController
                {...TextFieldControllerProps}
                key={field.id}
                controllerProps={{
                  rules: {
                    required:
                      isSmsEnabled && isEmptyList
                        ? t('customer_settings.form.notification_preferences.phone_required_error').toString()
                        : undefined,
                  },
                }}
                name={`${name}.${index}`}
              />

              <Tooltip
                title={
                  isSmsEnabled && fields.length === 1
                    ? t('customer_settings.form.notification_preferences.phone_required_error')
                    : undefined
                }
              >
                <Box>
                  <IconButton
                    aria-label="delete-phone"
                    color="error"
                    disabled={fields.length === 1}
                    size={'small'}
                    sx={{
                      ml: 1,
                      position: 'absolute',

                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                    onClick={() => {
                      remove(index)
                      clearErrors(`${name}.${index}` as Path<TFieldValues>)
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Box>
        <CustomButton
          color="primary"
          disabled={fields.length === maxPhoneCount}
          startIcon={<AddCircle />}
          variant="text"
          onClick={handleAddNewPhoneClick}
        >
          {t('sites.add_new.form.notifications.add_phone')}
        </CustomButton>
      </Box>
    </Stack>
  )
}
