import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import TextFieldController from '@/components/inputs/TextFieldController'
import { DEFAULT_PARTNER } from '@/constants/partners'
import environment from '@/environment'
import { CustomerAddressInput } from '@/features/customer/components/companyInfo/CustomerAddressInput'
import { SITE_STEPS_FIELD_WIDTH } from '@/features/customer/constants'
import type { Site } from '@/features/customer/types/site'
import determineTimezone from '@/features/customer/utils/determineTimezone'
import type { Partner } from '@/features/partner/types/partner'
import type { GpsLocation } from '@/types/gpsLocation'

type BasicInformationStepProps = {
  partners: Partner[]
}

function BasicInformationStep({ partners }: BasicInformationStepProps) {
  const { t } = useTranslation()

  const locationInfos = environment.getLocationsList()

  const { control, getValues, setValue } = useFormContext<Site>()

  const [availablePartners, setAvailablePartners] = useState<Partner[]>(partners)

  const [timezone, setTimezone] = useState<string | undefined>(getValues().timezone)

  function handleOnLocationChange(event) {
    const selectedLocation = event.target.value
    setValue('location', selectedLocation)

    const selectedLocationCountryCode =
      locationInfos.find((locationInfo) => locationInfo.location === event.target.value)?.country ?? ''

    const timezone = determineTimezone(selectedLocationCountryCode)
    setTimezone(timezone)
    setValue('timezone', timezone)

    setAvailablePartners(
      partners.filter(
        (partner) =>
          partner.partnerCode === DEFAULT_PARTNER.partnerCode || partner.countryCode === selectedLocationCountryCode,
      ),
    )

    const selectedPartner = partners.find((partner) => partner.partnerCode === getValues().partnerCode)
    if (
      (selectedPartner && selectedPartner.partnerCode !== DEFAULT_PARTNER.partnerCode) ||
      areDifferentCountries(selectedPartner?.countryCode, selectedLocationCountryCode)
    ) {
      setValue('partnerCode', DEFAULT_PARTNER.partnerCode)
    }
  }

  function areDifferentCountries(partnerCountryCode?: string, locationCountryCode?: string) {
    return partnerCountryCode && locationCountryCode && partnerCountryCode !== locationCountryCode
  }

  function onChangeAddress(address: string, gpsLocation: GpsLocation) {
    setValue('address', address, { shouldTouch: true })
    if (gpsLocation) {
      setValue('coordinates', { latitude: gpsLocation.lat!, longitude: gpsLocation.lon! }, { shouldTouch: true })
    }
  }

  const comparePartners = (a: Partner, b: Partner) => {
    if (a.name === DEFAULT_PARTNER.name) return -1
    if (b.name === DEFAULT_PARTNER.name) return 1
    return a.name.localeCompare(b.name)
  }

  return (
    <Grid container gap={2}>
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <TextFieldController required label={t('sites.add_new.form.name.label')} name="name" />
      </Grid>
      <Grid size={9} />
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <TextFieldController required label={t('sites.add_new.form.symbolic_name.label')} name="symbolicName" />
        <FormHelperText
          component="ul"
          sx={{
            paddingLeft: '30px',
            '& li': {
              display: 'list-item',
              listStyleType: 'disc',
            },
          }}
        >
          <li>{t('sites.add_new.form.symbolic_name.helper1')}</li>
          <li>{t('sites.add_new.form.symbolic_name.helper2')}</li>
          <li>{t('sites.add_new.form.symbolic_name.helper3')}</li>
          <li>{t('sites.add_new.form.symbolic_name.helper4')}</li>
        </FormHelperText>
      </Grid>
      <Grid size={9} />
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <Controller
          control={control}
          name="location"
          render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
            <CustomSelectField
              {...field}
              ref={fieldRef}
              fullWidth
              required
              error={invalid}
              helperText={error?.message}
              label={t('common.location')}
              name="location"
              options={locationInfos.map((info) => ({
                id: info.location,
                value: info.location,
                label: info.location,
              }))}
              value={value ?? ''}
              onChange={handleOnLocationChange}
            />
          )}
        />
      </Grid>
      <Grid alignContent={'center'} size={3}>
        <Typography> {timezone} </Typography>
      </Grid>
      <Grid size={9} />
      <Grid width={'350px'}>
        <Controller
          control={control}
          name="partnerCode"
          render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
            <CustomAutocomplete
              {...field}
              ref={fieldRef}
              disableClearable
              fullWidth
              error={invalid}
              helperText={error?.message}
              label={t('common.partner') + ' *'}
              options={
                availablePartners
                  ?.sort((a, b) => comparePartners(a, b))
                  .map((partner) => ({
                    id: partner.partnerCode,
                    value: partner.partnerCode,
                    label: partner.name,
                  })) ?? []
              }
              value={value ?? ''}
              onChange={(event, newValue) => {
                setValue('partnerCode', newValue)
              }}
            />
          )}
        />
      </Grid>
      <Grid size={9} />
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <CustomerAddressInput
          hiddenMap
          address={getValues('address')}
          gpsLocation={undefined}
          onChangeAddress={onChangeAddress}
        />
      </Grid>
      <Grid size={9} />
      <Grid alignContent={'center'} width={SITE_STEPS_FIELD_WIDTH}>
        <Typography> {t('common.type')} </Typography>
        <FormGroup>
          <RadioGroup
            aria-label="siteType"
            name="siteType"
            sx={{ ml: 2, mt: 1 }}
            value={getValues().siteType ?? ''}
            onChange={(event) => {
              setValue('siteType', event.target.value)
            }}
          >
            <FormControlLabel control={<Radio />} label={t('sites.types.sawmill')} value="sawmill" />
            <FormControlLabel control={<Radio />} label={t('sites.types.greenhouse')} value="greenhouse" />
            <FormControlLabel control={<Radio />} label={t('sites.types.other')} value="other" />
          </RadioGroup>
        </FormGroup>
      </Grid>
    </Grid>
  )
}

export default BasicInformationStep
