import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Divider, Grid2 as Grid, InputAdornment } from '@mui/material'
import { t } from 'i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'
import TextFieldController from '@/components/inputs/TextFieldController'
import { SITE_STEPS_FIELD_WIDTH } from '@/features/customer/constants'

type ContactFormProps = {
  index: number
  remove: (index: number) => void
  setValue: (name: any, value: any) => void
}

function ContactForm({ index, remove, setValue }: ContactFormProps) {
  return (
    <>
      <Grid container gap={3}>
        <Grid width={SITE_STEPS_FIELD_WIDTH}>
          <TextFieldController required label={t('common.name')} name={`contacts[${index}].name`} />
        </Grid>

        <Grid display="flex" justifyContent="flex-end" size={{ md: 8.5, sm: 3 }}>
          <CustomIconButton Icon={DeleteOutlinedIcon} aria-label={t('common.delete')} onClick={() => remove(index)} />
        </Grid>

        <Grid width={SITE_STEPS_FIELD_WIDTH}>
          <TextFieldController label={t('common.email')} name={`contacts[${index}].email`} />
        </Grid>

        <Grid size={{ md: 6, sm: 6 }} />

        <Grid width={SITE_STEPS_FIELD_WIDTH}>
          <TextFieldController
            label={t('common.phone')}
            name={`contacts[${index}].phone`}
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              },
            }}
            onChange={(event) => {
              const value = event.target.value.replace(/\D/g, '')
              setValue(`contacts[${index}].phone`, value)
            }}
          />
        </Grid>

        <Grid size={{ md: 6, sm: 6 }} />

        <Grid width={SITE_STEPS_FIELD_WIDTH}>
          <TextFieldController multiline label={t('common.comment')} name={`contacts[${index}].comment`} rows={2} />
        </Grid>

        <Grid size={{ md: 6, sm: 6 }} />
      </Grid>
      <Divider sx={{ my: 2 }} />
    </>
  )
}

export default ContactForm
