import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import { MarketProgramFilterTabs } from '@/features/customer/components/availability/MarketProgramFilterTabs'
import { RestrictionsDataGrid } from '@/features/customer/components/availability/restriction/RestrictionsDataGrid'
import { ServiceRulesDataGrid } from '@/features/customer/components/availability/serviceRule/ServiceRulesDataGrid'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'
import { NoMarketProgram } from '@/features/customer/components/NoMarketProgram'
import { useCustomerMarketProgramsQuery } from '@/features/customer/hooks/useCustomerMarketProgramsQuery'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { MarketProgramContract } from '@/features/customer/types/marketProgramContract'
import { sortMarketProgramContracts } from '@/features/customer/utils/sortMarketPrograms'
import type { MarketProgramType } from '@/types/marketProgramType'

export const CustomerUnavailability = () => {
  const { customer, isFetchingCustomer, customerDetailsCommonBreadcrumbs, customerSettings, setPageConfig } =
    useCustomerDetails()
  const { t } = useTranslation()
  const [selectedMarketProgram, setSelectedMarketProgram] = useState<MarketProgramContract>()

  const { marketProgramContracts, isFetching: isFetchingCustomerMarketPrograms } = useCustomerMarketProgramsQuery({
    uuid: customer.uuid!,
  })

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.unavailability.title'),
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      pageTag: 'customer-details-unavailability',
      appBarContent: <CustomerSelector customerUuid={customer.uuid} />,
      activeTab: CustomerDetailsRouteInformation.UNAVAILABILITY.navigationPath,
    })
  }, [])

  if (isFetchingCustomer || isFetchingCustomerMarketPrograms) return null

  const activeMarketPrograms = sortMarketProgramContracts(
    t,
    marketProgramContracts?.filter((contract) => !contract.isDisabled) ?? [],
  )

  if (activeMarketPrograms.length === 0) {
    return <NoMarketProgram />
  }

  const customerMarketProgramTypes = activeMarketPrograms.map((program) => program.service.type as MarketProgramType)

  const marketProgram = selectedMarketProgram ?? activeMarketPrograms[0]
  const marketProgramType = selectedMarketProgram?.service.type ?? activeMarketPrograms[0].service.type

  const handleChange = (newValue: MarketProgramType) => {
    setSelectedMarketProgram(activeMarketPrograms?.find((program) => program.service.type === newValue))
  }

  return (
    <MainContentContainer>
      <MarketProgramFilterTabs
        activeMarketPrograms={customerMarketProgramTypes}
        value={marketProgramType}
        onChange={handleChange}
      />
      {isMarketProgramAvailability(marketProgram.service) ? (
        <ServiceRulesDataGrid
          customerLocation={customer.location ?? ''}
          customerTimeZone={customerSettings.localization.timeZone ?? ''}
          customerUuid={customer.uuid ?? ''}
          selectedMarketProgram={marketProgram}
        />
      ) : (
        <RestrictionsDataGrid
          customerLocation={customer.location ?? ''}
          customerTimeZone={customerSettings.localization.timeZone ?? ''}
          customerUuid={customer.uuid ?? ''}
          selectedMarketProgram={marketProgram.service}
        />
      )}
    </MainContentContainer>
  )
}

function isMarketProgramAvailability(program: MarketProgram) {
  return !program.isReserve || program.reserve === 'afrr'
}
