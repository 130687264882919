import type { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

export type TextFieldControllerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  TextFieldProps,
  'name'
> & {
  name: TName
  // It enables showing field validation errors. Its default value is true
  showError?: boolean
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'render' | 'name'>
}

export default function TextFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  showError = true,
  controllerProps,
  fullWidth,
  type,
  ...fieldProps
}: TextFieldControllerProps<TFieldValues, TName>) {
  const { control } = useFormContext<TFieldValues>()

  const numberInputProps =
    type === 'number'
      ? {
          inputProps: {
            onWheel: (e: React.WheelEvent<HTMLInputElement>) => {
              e.preventDefault()
              e.currentTarget.blur()
            },
          },
        }
      : {}

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error, invalid } }) => (
        <TextField
          {...field}
          error={showError && invalid}
          fullWidth={fullWidth ?? true}
          helperText={error?.message}
          name={name}
          type={type}
          value={value ?? ''}
          {...fieldProps}
          {...numberInputProps}
        />
      )}
    />
  )
}
