import type { AxiosResponse } from 'axios'
import axios from 'axios'

import environment from '@/environment'
import type { Column, FormattedRow } from '@/features/bessDashboard/types'

function formatResponse(response: any | undefined): FormattedRow[] {
  if (!response) {
    return [] // Return an empty array if response is undefined
  }

  const content = response.data

  const { columns } = content.manifest.schema
  const { data_array } = content.result

  const formattedData: FormattedRow[] = data_array.map((row: string[]) => {
    const formattedRow: FormattedRow = {}
    columns.forEach((col: Column, index: number) => {
      formattedRow[col.name] = row[index]
    })
    return formattedRow
  })

  return formattedData
}

export async function getForecasts(startRange: string, endRange: string): Promise<FormattedRow[]> {
  const sqlQuery = `select * from flexportal_sharing.bess_dashboard.market_forecast_all WHERE ds BETWEEN '${startRange}' AND '${endRange}'`
  const url =
    environment.environmentName === 'production'
      ? `${environment.services.databricksMarketForecasts}?table=MARKET_FORECAST_ALL&fromTimestamp=${startRange}&toTimestamp=${endRange}`
      : `${environment.services.databricksSharingApiUrl}?sql-statement=${sqlQuery}`

  const response: AxiosResponse = await axios.get(url)

  return formatResponse(response)
}

export async function getPriceImpact(startRange: string, endRange: string): Promise<FormattedRow[]> {
  const sqlQuery = `SELECT * FROM flexportal_sharing.bess_dashboard.market_forecast_price_impact WHERE local_time BETWEEN '${startRange}' AND '${endRange}'`

  const url =
    environment.environmentName === 'production'
      ? `${environment.services.databricksMarketForecasts}?table=MARKET_FORECAST_PRICE_IMPACT&fromTimestamp=${startRange}&toTimestamp=${endRange}`
      : `${environment.services.databricksSharingApiUrl}?sql-statement=${sqlQuery}`

  const response: AxiosResponse = await axios.get(url)

  return formatResponse(response)
}
