import { InputAdornment } from '@mui/material'
import type { FieldPath, FieldValues, PathValue } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { TextFieldControllerProps } from '@/components/inputs/TextFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import validatePhone from '@/utils/phone'

export type PhoneNumberFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = TextFieldControllerProps<TFieldValues, TName>

export default function PhoneNumberFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({ controllerProps, ...textFieldProps }: Readonly<PhoneNumberFieldControllerProps<TFieldValues, TName>>) {
  const { t } = useTranslation()
  const { setValue } = useFormContext<TFieldValues>()
  const { rules, ...controllerPropsRest } = controllerProps ?? {}

  return (
    <TextFieldController
      {...textFieldProps}
      controllerProps={{
        ...controllerPropsRest,
        rules: {
          ...rules,
          validate: (value) => {
            if (!validatePhone(value)) {
              return t('customer_settings.form.notification_preferences.phone_invalid_error').toString()
            }

            return true
          },
        },
      }}
      slotProps={{
        input: {
          startAdornment: <InputAdornment position="start">+</InputAdornment>,
        },
      }}
      onChange={(event) => {
        const value = event.target.value.replace(/\D/g, '')

        setValue(textFieldProps.name, value as PathValue<TFieldValues, TName>)
      }}
    />
  )
}
