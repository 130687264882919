import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import ReactECharts from 'echarts-for-react'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { COMMON_CHART_OPTIONS } from '@/features/bessDashboard/constants/chart'
import type { FormattedRow } from '@/features/bessDashboard/types'
import {
  constructDateString,
  createRenderItem,
  filterByMarketProgram,
  filterByType,
  findOverallMinMaxForColumns,
  getTimeZoneString,
  getWeekendMarkAreas,
} from '@/features/bessDashboard/utils/chart'
import { formatDateTimeForPeriodSelection } from '@/features/bessDashboard/utils/utils'

type ForecastChartProps = {
  title: string
  infoText: string
  unit?: string | null
  filterType?: string | null
  forecastValues: FormattedRow[]
  marketProgram: string
  startTime: DateTime
  endTime: DateTime
  isLoading: boolean
  isFetching: boolean
}

function ForecastChart({
  title,
  infoText,
  unit = '',
  filterType = null,
  forecastValues,
  marketProgram,
  startTime,
  endTime,
  isLoading,
  isFetching,
}: Readonly<ForecastChartProps>) {
  const { t } = useTranslation()

  const chartColors = {
    forecast: 'rgba(241,163,64, 1.0)',
    actual: 'rgba(153, 142, 195, 1.0)',
    p80: 'rgba(241,163,64, 0.1)',
    p50: 'rgba(241,163,64, 0.2)',
    weekend: 'rgba(110, 112, 121, 0.1)',
  }

  let minMax = findOverallMinMaxForColumns(forecastValues, 'forecast-lo-80', 'forecast-hi-80')

  let data = filterByMarketProgram(forecastValues, marketProgram)

  if (filterType) {
    minMax = findOverallMinMaxForColumns(filterByType(forecastValues, filterType), 'forecast-lo-80', 'forecast-hi-80')
    data = filterByType(data, filterType)
  }

  if (data.length === 0) {
    return (
      <Paper sx={{ p: 2, height: '240px' }} variant="outlined">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Typography variant="h2">No data for Date Range: </Typography>
          <Box>
            {startTime.toISODate()} {endTime.toISODate()}
          </Box>
        </Box>
      </Paper>
    )
  }

  const highData = data.map((d, i) => [i, d['forecast-lo-80'], d['forecast-hi-80']])
  const lowData = data.map((d, i) => [i, d['forecast-lo-50'], d['forecast-hi-50']])

  const weekendAreas = getWeekendMarkAreas(data)

  const option = {
    ...COMMON_CHART_OPTIONS,
    grid: {
      bottom: 80,
    },
    legend: {
      data: ['Actual', 'Forecast', 'P80', 'P50'],
      top: '6%',
    },
    dataZoom: [
      {
        // throttle: 1, // Throttle the dataZoom eventsx
        start: 0,
        end: 100,
        labelFormatter: (value) => {
          const date = new Date(data[value].ds)
          const { dayPart, timePart } = constructDateString(date)
          return dayPart + ' ' + timePart + ' ' + getTimeZoneString()
        },
      },
    ],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      // axisLine: { onZero: false },
      data: data.map(function (item) {
        return item.ds
      }),

      axisLabel: {
        formatter: (value) => {
          const date = new Date(value)
          const { dayPart, timePart } = constructDateString(date)
          // Return the date and time, separated by a newline for the two-line format
          return `${timePart}\n${dayPart}`
        },
        rotate: 0,
      },
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: true,
      },
      min: -30,
      max: Number(minMax.overallMax + 10),
      axisLabel: {
        formatter: (value) => `${Number(value).toFixed(0)} ${unit}`,
      },
    },
    series: [
      {
        name: 'Actual',
        type: 'line',
        smooth: false,
        step: 'end',
        data: data.map(function (item) {
          return item.actual
        }),
        lineStyle: {
          width: 2,
          // type: 'dashed',
        },
        itemStyle: {
          color: chartColors.actual,
        },
        showSymbol: false,
        markArea: {
          silent: true, // Disable interaction with shaded areas
          itemStyle: {
            color: chartColors.weekend, // Light gray color
          },
          data: weekendAreas,
        },
      },
      {
        name: 'Forecast',
        type: 'line',
        smooth: false,
        step: 'end',
        data: data.map((item) => item.mean),
        lineStyle: {
          width: 2,
          // type: 'dashed',
        },
        itemStyle: {
          color: chartColors.forecast,
        },
        showSymbol: false,
      },

      {
        name: 'P80',
        type: 'custom',
        data: highData,
        animation: false,
        animationDuration: 1000, // Adjust to your needs
        animationEasing: 'linear', // Linear easing for smooth motion
        renderItem: createRenderItem({
          fill: chartColors.p80,
        }),
        itemStyle: { color: chartColors.p80 },
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          tooltip: [1, 2],
        },
        dimensions: [null, 'P80 lo', 'P80 hi'],
      },
      {
        name: 'P50',
        type: 'custom',
        data: lowData,
        animation: false,
        animationDuration: 1000, // Adjust to your needs
        animationEasing: 'linear', // Linear easing for smooth motion
        itemStyle: { color: chartColors.p50 },
        renderItem: createRenderItem({
          fill: chartColors.p50,
        }),
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          tooltip: [1, 2],
        },
        dimensions: [null, 'P50 lo', 'P50 hi'],
      },
    ],
  }

  return (
    <Paper sx={{ p: 2, mb: 3 }} variant="outlined">
      {(isLoading || isFetching) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Stack spacing={2}>
        <Box display="flex" flexDirection="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">{title}</Typography>{' '}
          <Box display="flex" flexDirection="column" sx={{ justifyContent: 'space-between' }}>
            <Typography fontSize={14} textAlign="right">
              {`${formatDateTimeForPeriodSelection(startTime.setZone('UTC'))} `}
              <i>{t('common.period.to_lowercase')}</i>
              {` ${formatDateTimeForPeriodSelection(endTime.setZone('UTC'))}`}
            </Typography>
            <Typography fontSize={12} textAlign="right">
              Times displayed in {getTimeZoneString()}
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={1}>
          <InfoOutlinedIcon color={'info'} width={'20px'} />
          <Typography fontSize={16} maxWidth={1000} variant="body1">
            {infoText}
          </Typography>
        </Stack>
        <ReactECharts notMerge option={option} style={{ height: '500px', padding: '0' }} />
      </Stack>
    </Paper>
  )
}

export default ForecastChart
