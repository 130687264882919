import { AccountCircleOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/features/authentication/contexts/AuthContext'
import NumericWidget from '@/features/dashboard/components/NumericWidget'
import { NumericWidgetSkeleton } from '@/features/dashboard/components/NumericWidgetSkeleton'
import { useUsersQuery } from '@/features/user/hooks/useUsersQuery'

function UserCountWidget() {
  const { loggedInUser } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { users, isLoading } = useUsersQuery({ partnerCode: loggedInUser?.partnerCode })

  if (isLoading) {
    return <NumericWidgetSkeleton />
  }

  return (
    <NumericWidget
      icon={<AccountCircleOutlined color="secondary" fontSize="large" />}
      title={t('component.navbar.users')}
      value={users?.filter((user) => user.isActive).length ?? 0}
      onClick={() => navigate(`/users`)}
    />
  )
}

export default UserCountWidget
