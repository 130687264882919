import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Autocomplete, Box, FormControl, Paper, Stack, TextField, Tooltip } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import LanguageFieldController from '@/features/customer/components/LanguageFieldController'
import type { CustomerSettingsFormValues } from '@/features/customer/components/settings/CustomerSettingsForm'

export const CustomerLocalizationSettingsSection = () => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<CustomerSettingsFormValues>()

  return (
    <Box>
      <CustomTypography gutterBottom variant="h4">
        {t('customer_settings.form.localization_settings_title')}
      </CustomTypography>
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Stack noValidate component="form" maxWidth={400} spacing={2}>
          <FormControl>
            <LanguageFieldController showTooltip name="localization.uiLanguage" />
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="localization.timeZone"
              render={({ field }) => (
                <Stack alignItems={'center'} direction={'row'} justifyContent={'flex-start'} spacing={2}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    disableClearable={true}
                    options={Intl.supportedValuesOf('timeZone')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('customer_settings.form.timezone_label')}
                        slotProps={{
                          input: params.InputProps,
                        }}
                      />
                    )}
                    value={field.value}
                    onChange={(event, newValue) => {
                      setValue('localization.timeZone', newValue)
                    }}
                  />
                  <Tooltip title={t('customer_settings.form.timezone_hint')}>
                    <HelpOutlineIcon color={'action'} />
                  </Tooltip>
                </Stack>
              )}
            />
          </FormControl>
        </Stack>
      </Paper>
    </Box>
  )
}
