import type { SxProps } from '@mui/material'
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FlagIcon from '@/components/dataDisplay/FlagIcon'
import type { InterfaceLanguage } from '@/constants/interfaceLanguages'
import { DEFAULT_INTERFACE_LANGUAGE, INTERFACE_LANGUAGES } from '@/constants/interfaceLanguages'

export interface LanguageSelectorProps {
  initialValue?: string
  onChange: (countryCode: string) => void
  sx?: SxProps
  label: string
}

const LanguageSelector = (
  { initialValue, onChange, sx, label, ...props }: LanguageSelectorProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const { t } = useTranslation()

  const [value, setValue] = useState<InterfaceLanguage>(
    INTERFACE_LANGUAGES.find((language) => language.value === initialValue) || DEFAULT_INTERFACE_LANGUAGE,
  )

  return (
    <Autocomplete
      ref={ref}
      autoHighlight
      getOptionLabel={(option) => t(`common.interface_language.${option.value}`)}
      options={INTERFACE_LANGUAGES.toSorted((a, b) =>
        t(`common.interface_language.${a.value}`).localeCompare(t(`common.interface_language.${b.value}`)),
      )}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue?.value || '')
        setValue(newValue)
      }}
      {...props}
      fullWidth
      disableClearable={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment: value ? (
                <InputAdornment position="start">
                  <FlagIcon countryCode={value.countryCode} sx={{ ml: 1 }} />
                </InputAdornment>
              ) : null,
            },
          }}
        />
      )}
      renderOption={({ key, ...props }, option) => (
        <Box key={key} component="li" {...props}>
          <FlagIcon countryCode={option.countryCode} sx={{ mr: 2, flexShrink: 0 }} />
          {t(`common.interface_language.${option.value}`)}
        </Box>
      )}
      sx={sx}
    />
  )
}

export default forwardRef(LanguageSelector)
