import { InfoOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

type InfoFormTextProps = {
  text: string
}

export default function InfoFormText({ text }: Readonly<InfoFormTextProps>) {
  return (
    <Stack
      sx={{
        gap: 0.75,
        flexDirection: 'row',
      }}
    >
      <InfoOutlined color="info" />

      <CustomTypography variant="body1">{text}</CustomTypography>
    </Stack>
  )
}
