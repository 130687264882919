import type { TFunction } from 'i18next'
import { z } from 'zod'

import validatePhone from '@/utils/phone'

const MAX_PHONE_COUNT = 10
const MIN_PHONE_COUNT = 1

export const getSiteFormSchema = (t: TFunction) =>
  z.object({
    name: z.string().trim().min(1, t('sites.add_new.form.name.error')),
    partnerCode: z
      .string()
      .trim()
      .min(1, t('sites.add_new.form.partner_code.error'))
      .nullable()
      .refine((val) => val !== null, t('sites.add_new.form.partner_code.error')),
    symbolicName: z.string().trim().min(1, t('sites.add_new.form.symbolic_name.error')),
    address: z.string().optional(),
    location: z.string().trim().min(2, t('sites.add_new.form.location.error')),
    siteType: z.string().optional(),
    contacts: z.array(
      z.object({
        name: z
          .string()
          .trim()
          .min(1, t('customer_details.tabs.contact_info.contacts.name_required.error'))
          .nullable()
          .refine((val) => val !== null, t('customer_details.tabs.contact_info.contacts.name_required.error')),
        phone: z
          .string()
          .refine((val) => validatePhone(val), t('customer_details.tabs.contact_info.phone_invalid.error'))
          .optional(),
        email: z
          .string()
          .trim()
          .email(t('customer_details.tabs.contact_info.email_invalid.error'))
          .or(z.literal(''))
          .optional(),
        comment: z.string().optional(),
      }),
    ),
    notificationPreferences: z
      .object({
        phones: z
          .array(
            z.string().refine((val) => validatePhone(val), t('sites.add_new.form.notifications.errors.phone_invalid')),
          )
          .max(MAX_PHONE_COUNT)
          .min(MIN_PHONE_COUNT)
          .optional(),
        email: z.string().min(0).email(t('sites.add_new.form.notifications.errors.email_invalid')).optional(),
        isSmsEnabled: z.boolean(),
        isEmailEnabled: z.boolean(),
      })
      .refine((data) => !data.isEmailEnabled || (data.isEmailEnabled && data.email && data.email.length > 0), {
        message: t('sites.add_new.form.notifications.errors.email_required'),
        path: ['email'],
      })
      .refine((data) => !data.isSmsEnabled || (data.isSmsEnabled && data.phones && data.phones[0].length > 0), {
        message: t('sites.add_new.form.notifications.errors.phone_required'),
        path: ['phones', 0],
      }),
  })
