const {
  services: servicesConfig,
  muiProLicenseKey: muiProLicenseKeyConfig,
  apiBaseUrl: apiBaseUrlConfig,
  apiInternalBaseUrl,
  apiWhitelabelConfigUrl,
  locationsList: locationsListConfig,
  loggingSupportToolboxUrl: loggingSupportToolboxUrlConfig,
  grafanaSupportToolboxUrl: grafanaSupportToolboxUrlConfig,
  ...config
} = window.FlexPortalConfig ?? {}

const API_BASE_URL = process.env.VITE_APP_API_BASE_URL ?? apiBaseUrlConfig ?? 'https://api.staging.sympower.net'
const API_INTERNAL_BASE_URL =
  process.env.VITE_APP_API_INTERNAL_BASE_URL ?? apiInternalBaseUrl ?? 'https://api.staging-service.sympower.net'
const API_WHITELABEL_CONFIG_BASE_URL =
  process.env.VITE_APP_API_WHITELABEL_CONFIG_URL ??
  apiWhitelabelConfigUrl ??
  'https://whitelabel.staging-service.sympower.net'

// Environment contains all the configuration that is needed for the application to run. All configuration properties can be
// overridden on developmentby environment variables that start with VITE_APP_ (there are some xamples on the .env.example file).
//
// Whare are providing a mechanims to override the configuration properties by using the window.FlexPortalConfig object. This object
// can be set at runtime. This is useful for example when we want to override the configuration properties on some test environments or
// at deployment phase.
//
// The environment object includes some default values, so developers can run the application without setting any environment variable.
const environment = {
  allCountries: (process.env.VITE_APP_ALL_COUNTRIES as CountryIdentifier[] | undefined) ?? [
    'fi',
    'gr',
    'nl',
    'no',
    'se',
  ],

  allLocations: (process.env.VITE_APP_ALL_LOCATIONS as CountryIdentifier[] | undefined) ?? [
    'fi',
    'gr',
    'nl',
    'no',
    'se',
  ],

  defaultLocation: process.env.VITE_APP_DEFAULT_LOCATION ?? 'fi',

  getLocationsList(): LocationInfo[] {
    return (
      locationsListConfig ??
      this.allLocations.map((location) => ({
        location: location,
        country: location,
        price_area: location,
      }))
    )
  },

  apiBaseUrl: API_BASE_URL,

  apiInternalBaseUrl: API_INTERNAL_BASE_URL,

  environmentName: process.env.VITE_APP_ENVIRONMENT_NAME ?? 'development',

  cognitoUserPoolId: process.env.VITE_APP_COGNITO_USER_POOL_ID ?? 'eu-central-1_FhgP6VCj2',

  cognitoWebClientId: process.env.VITE_APP_COGNITO_WEB_CLIENT_ID ?? '1c20k20jsjvbpfm4qcpuj6ru3k',

  deployedVersion: process.env.VITE_APP_DEPLOY_VERSION ?? 'N/A in development',

  theme: process.env.VITE_APP_THEME ?? 'sympower',

  useDefaultWhitelabelConfig: process.env.VITE_APP_USE_DEFAULT_WHITELABEL_CONFIG ?? false,

  isDevelopmentMode: process.env.NODE_ENV === 'development',

  getMuiProLicenseKey(): string {
    return (
      process.env.VITE_APP_MUI_PRO_PLAN_LICENSE_KEY ??
      muiProLicenseKeyConfig ??
      'c72adadea14a290e48de28fe4c742ef4Tz05ODY1MixFPTE3NTg3MjIwMTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
    )
  },

  googleAnalyticsTrackId: process.env.VITE_APP_GOOGLE_ANALYTICS_TRACK_ID ?? 'G-N0BS5WCB1M',

  googleMapsApiKey: process.env.VITE_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyA9HFzaSYd19m7t7u-uz2P5CPOMArATlDY',

  sentryDSN:
    process.env.VITE_APP_SENTRY_DSN ??
    'https://804013dd4b5745248c0b05402ce95a02@o944537.ingest.sentry.io/4504446446338048',

  ...config,

  // List of API micro services that we use to get and manipulate data from Flex Portal.
  // There is more information about the API tree in the following link:
  // https://www.notion.so/sympower/3d9b5dfacb3c490d87762ad547e811dd
  services: {
    activationsBffApiUrl: process.env.VITE_APP_ACTIVATIONS_BFF_API_URL ?? `${API_BASE_URL}/api/v1/activations`,

    usersManagerApiUrl: process.env.VITE_APP_USERS_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/users`,

    portfolioManagerApiUrl:
      process.env.VITE_APP_PORTFOLIO_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/portfolio-manager`,

    prequalificationManagerApiUrl:
      process.env.VITE_APP_PREQUALIFICATION_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/prequalification-manager`,

    resourcesManagerApiUrl: process.env.VITE_APP_RESOURCES_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/resource-bff`,

    resourceSelectionManagerApiUrl:
      process.env.VITE_APP_RESOURCE_SELECTION_MANAGER_API_URL ?? `${API_BASE_URL}/api/v2/nordics-static-fcrd`,

    insightsManagerApiUrl: process.env.VITE_APP_INSIGHTS_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/insights`,

    partnersManagerApiUrl: process.env.VITE_APP_PARTNERS_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/partners`,

    sitesManagerApiUrl: process.env.VITE_APP_SITES_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/sites`,

    whitelabelConfigManagerApiUrl:
      process.env.VITE_APP_WHITELABEL_CONFIG_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/whitelabel`,

    whitelabelConfigManagerPublicApiUrl:
      process.env.VITE_APP_WHITELABEL_CONFIG_MANAGER_PUBLIC_API_URL ??
      `${API_WHITELABEL_CONFIG_BASE_URL}/public/api/v1/whitelabel`,

    monolithGatewayManagerApiUrl:
      process.env.VITE_APP_MONOLITH_GATEWAY_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/monolith-api-gw`,

    featureTogglesManagerApiUrl:
      process.env.VITE_APP_FEATURE_TOGGLES_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/features`,

    integrationsManagerApiUrl:
      process.env.VITE_APP_INTEGRATIONS_MANAGER_API_URL ?? `${API_BASE_URL}/api/v1/integrations`,

    databricksSharingApiUrl:
      process.env.VITE_APP_DATABRICKS_SHARING_API_URL ?? `${API_BASE_URL}/api/v1/databricks-sharing`,

    databricksMarketForecasts:
      process.env.VITE_APP_DATABRICKS_MARKET_FORECASTS_API_URL ?? `${API_BASE_URL}/api/v1/market-forecasts`,
    biddingInsightsApiUrl: process.env.VITE_APP_BIDDING_INSIGHTS_API_URL ?? `${API_BASE_URL}/api/v1/bidding-insights`,

    resourceScheduleActionsApiUrl:
      process.env.VITE_APP_RESOURCE_SCHEDULE_ACTIONS_API_URL ?? `${API_BASE_URL}/api/v1/resource/schedule/actions`,

    resourceScheduleBuilderApiUrl:
      process.env.VITE_APP_RESOURCE_SCHEDULE_BUILDER_API_URL ?? `${API_BASE_URL}/api/v1/resource/schedule/builder`,

    ...servicesConfig,
  },
  dashboards: {
    // eslint-disable-next-line sonarjs/no-clear-text-protocols
    loggingSupportToolboxUrl:
      process.env.VITE_APP_LOGGING_SUPPORT_TOOLBOX_URL ??
      loggingSupportToolboxUrlConfig ??
      `http://kibana.symp/app/data-explorer/discover#?_a=(metadata:(indexPattern:'18873440-41c8-11ea-bd98-b18e89b7b7f6',view:discover))&_q=(query:(language:kuery,query:'message:~resource-placeholder~'))`,
    grafanaSupportToolboxUrl:
      process.env.VITE_APP_GRAFANA_SUPPORT_TOOLBOX_URL ??
      grafanaSupportToolboxUrlConfig ??
      `https://grafana.sympower.net/d/ee3thy3tqhqtcc/resource-insight?orgId=1&from=now-1h&to=now&timezone=utc&var-environment=staging&var-resourceId=~resource-placeholder~`,
  },
}

export default environment
