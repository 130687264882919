import { IconChevronLeft, IconChevronRight, IconClose } from '@aws-amplify/ui-react/internal'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import { DEFAULT_PARTNER } from '@/constants/partners'
import { useAlertContext } from '@/contexts/AlertContext'
import type { SiteCreateStep } from '@/features/activation/types/siteCreateStep'
import BasicInformationStep from '@/features/customer/components/create/BasicInformationStep'
import ContactsStep from '@/features/customer/components/create/ContactsStep'
import NotificationsStep from '@/features/customer/components/create/NotificationsStep'
import { saveSite as apiSaveSite } from '@/features/customer/endpoints/sites'
import { getSiteFormSchema } from '@/features/customer/schemas'
import type { Site } from '@/features/customer/types/site'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import { errorHandler } from '@/utils/errorHandler'

const steps = ['basic_information', 'contact_details', 'notifications', 'financial_information', 'market_programs']

export default function SiteCreate() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)

  const { partners, isFetching: isFetchingPartners } = usePartnersQuery()

  const form = useForm<Site>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      partnerCode: DEFAULT_PARTNER.partnerCode,
      symbolicName: '',
      location: '',
      contacts: [],
      notificationPreferences: {
        language: 'en',
        isSmsEnabled: false,
        isEmailEnabled: false,
      },
    },
    resolver: zodResolver(getSiteFormSchema(t)),
  })

  useWatch({
    control: form.control,
  })

  const { mutate: saveSite, isPending } = useMutation({
    mutationFn: async () => {
      const sitePayload = form.getValues()
      return await apiSaveSite(sitePayload)
    },
    onError: (err: any) => {
      const error = errorHandler(err, t('sites.add_new.error'))

      pushAlert({
        severity: 'error',
        message: error.message,
      })
    },
    onSuccess: async () => {
      navigate('/customers')
    },
  })

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    setActiveStep(4)
  }

  function getStepContent(activeStep: number) {
    switch (activeStep) {
      case 0:
        return <BasicInformationStep partners={partners!} />
      case 1:
        return <ContactsStep />
      case 2:
        return <NotificationsStep />
      case 3:
        return null
      case 4:
        return null
      default:
        return null
    }
  }

  function onSubmit() {
    saveSite()
  }

  function isLastStep() {
    return activeStep === steps.length - 1
  }

  if (isFetchingPartners) {
    return null
  }

  return (
    <FormProvider {...form}>
      <Box sx={{ width: '100%', mt: 4 }} onSubmit={form.handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{t(`sites.add_new.steps.${label as SiteCreateStep}`)}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography sx={{ mb: 3 }} variant={'h5'}>
            {t(`sites.add_new.steps.${steps[activeStep] as SiteCreateStep}`)}
          </Typography>

          {getStepContent(activeStep)}
        </Paper>

        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
          <CustomButton startIcon={<IconClose />} sx={{ mr: 1 }} onClick={() => navigate(-1)}>
            {t('common.button.cancel')}
          </CustomButton>
          {activeStep > 0 && (
            <CustomButton startIcon={<IconChevronLeft />} sx={{ mr: 1 }} variant={'outlined'} onClick={handleBack}>
              {t('common.button.back')}
            </CustomButton>
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          {!isLastStep() && (
            <CustomButton endIcon={<IconChevronRight />} sx={{ mr: 1 }} onClick={handleSkip}>
              {t('common.button.skip_to_end')}
            </CustomButton>
          )}
          {isLastStep() ? (
            <CustomButton disabled={isPending} type="submit" variant="contained" onClick={onSubmit}>
              {t('common.button.save')}
            </CustomButton>
          ) : (
            <CustomButton endIcon={<IconChevronRight />} variant={'contained'} onClick={handleNext}>
              {t('common.button.next')}
            </CustomButton>
          )}
        </Box>
      </Box>
    </FormProvider>
  )
}
