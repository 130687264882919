import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import {
  activateCustomer as apiActivateCustomer,
  deactivateCustomer as apiDeactivateCustomer,
} from '@/features/customer/endpoints/customers'

export type UseUpdateCustomerStatusMutationResult = ReturnType<typeof useUpdateCustomerStatusMutation>

export function useUpdateCustomerStatusMutation() {
  const { pushAlert } = useAlertContext()
  const { t } = useTranslation()

  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { isActive: boolean; uuid: string; location: string; name: string }) => {
      return variables.isActive
        ? apiActivateCustomer(variables.uuid, variables.location)
        : apiDeactivateCustomer(variables.uuid, variables.location)
    },
    onSuccess: async (_, variables) => {
      const { isActive, name } = variables

      pushAlert({
        message: t(`customers.customer_disable.customer_is_now_${isActive ? 'enabled' : 'disabled'}_success_message`, {
          customerName: name,
        }),
        severity: 'success',
      })
    },
  })

  return {
    updateCustomerStatus: mutateAsync,
    ...mutationResult,
  }
}
