import { MarketProgram } from '@/features/bidding/constants'

export const CountryBiddableMarketProgramMap: Record<CountryIdentifier, MarketProgram[]> = {
  ee: [],
  es: [],
  dk: [],
  nl: [],
  fi: [
    MarketProgram.NORDICS_FCRD_DOWN_STATIC,
    MarketProgram.NORDICS_FCRD_UP_STATIC,
    MarketProgram.NORDICS_FCRD_DOWN_DYNAMIC,
    MarketProgram.NORDICS_FCRD_UP_DYNAMIC,
    MarketProgram.NORDICS_FCRN,
    MarketProgram.FCRN,
    MarketProgram.FFR_UP,
  ],
  gr: [MarketProgram.MFRR_UP, MarketProgram.MFRR_DOWN],
  no: [],
  se: [
    MarketProgram.FCRD_UP,
    MarketProgram.FCRD_DOWN,
    MarketProgram.MFRR_UP,
    MarketProgram.NORDICS_FCRD_DOWN_STATIC,
    MarketProgram.NORDICS_FCRD_UP_STATIC,
    MarketProgram.NORDICS_FCRD_DOWN_DYNAMIC,
    MarketProgram.NORDICS_FCRD_UP_DYNAMIC,
  ],
  hu: [MarketProgram.MFRR_UP],
}
