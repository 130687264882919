import { Box, Grid2, Stack } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import EmailFieldController from '@/features/customer/components/EmailFieldController'
import InfoFormText from '@/features/customer/components/InfoFormText'
import LanguageFieldController from '@/features/customer/components/LanguageFieldController'
import { PhoneNumberListFieldController } from '@/features/customer/components/PhoneNumberListFieldController'
import { SITE_STEPS_FIELD_WIDTH } from '@/features/customer/constants'
import type { Site } from '@/features/customer/types/site'

const MAX_PHONE_COUNT = 10

function NotificationsStep() {
  const { t } = useTranslation()
  const { watch, setValue, trigger } = useFormContext<Site>()

  const isEmailEnabled = watch('notificationPreferences.isEmailEnabled')
  const isSmsEnabled = watch('notificationPreferences.isSmsEnabled')

  return (
    <Grid2 container direction="column" gap={5}>
      <Grid2 size={{ md: 8, sm: 6 }}>
        <InfoFormText text={t('sites.add_new.form.notifications.info')} />
      </Grid2>

      <Grid2 width={SITE_STEPS_FIELD_WIDTH}>
        <LanguageFieldController name="notificationPreferences.language" />
      </Grid2>

      <Grid2 width={SITE_STEPS_FIELD_WIDTH}>
        <Stack flexDirection="column" gap={2}>
          <Box>
            <CustomTypography
              sx={{
                marginBottom: 1,
              }}
              variant="h5"
            >
              {t('sites.add_new.form.notifications.message_types.label')}
            </CustomTypography>

            <Stack flexDirection="row" gap={3.75}>
              <CheckboxController
                label={t('common.email')}
                name="notificationPreferences.isEmailEnabled"
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  const { checked } = evt.target

                  setValue('notificationPreferences.isEmailEnabled', checked)

                  // We need to trigger validation for the email numbers when Email checkbox change
                  trigger('notificationPreferences.email')
                }}
              />

              <CheckboxController
                label={t('sites.add_new.form.notifications.message_types.sms')}
                name="notificationPreferences.isSmsEnabled"
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  const { checked } = evt.target

                  setValue('notificationPreferences.isSmsEnabled', checked)

                  // We need to trigger validation for the phone numbers when SMS checkbox change
                  trigger('notificationPreferences.phones.0')
                }}
              />
            </Stack>
          </Box>

          <Stack flexDirection="column" gap={5}>
            <EmailFieldController
              isEmailEnabled={isEmailEnabled}
              label={t('common.email')}
              name="notificationPreferences.email"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target

                // We need to set the value to undefined if it's an empty string to avoid invalid email errors
                // when there is no email set
                setValue('notificationPreferences.email', value === '' ? undefined : value)
              }}
            />

            <PhoneNumberListFieldController
              TextFieldControllerProps={{
                label: t('common.phone'),
              }}
              isSmsEnabled={isSmsEnabled}
              maxPhoneCount={MAX_PHONE_COUNT}
              name="notificationPreferences.phones"
            />
          </Stack>
        </Stack>
      </Grid2>
    </Grid2>
  )
}

export default NotificationsStep
