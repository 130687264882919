import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Stack, Tooltip } from '@mui/material'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { LanguageSelectorProps } from '@/components/inputs/LanguageSelector'
import LanguageSelector from '@/components/inputs/LanguageSelector'

export type LanguageFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<LanguageSelectorProps, 'name' | 'label' | 'onChange'> & {
  name: TName
  showTooltip?: boolean
}

export default function LanguageFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, showTooltip, ...languageSelectorProps }: LanguageFieldControllerProps<TFieldValues, TName>) {
  const { control } = useFormContext<TFieldValues>()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ...field } }) => (
        <Stack
          alignItems={'center'}
          direction={'row'}
          justifyContent={'flex-start'}
          spacing={2}
          sx={{ position: 'relative' }}
        >
          <LanguageSelector
            {...field}
            {...languageSelectorProps}
            initialValue={value}
            label={t('customer_settings.form.interface_language_label')}
            onChange={onChange}
          />

          {showTooltip && (
            <Tooltip title={t('customer_settings.form.interface_language_hint')}>
              <HelpOutlineIcon color={'action'} />
            </Tooltip>
          )}
        </Stack>
      )}
    />
  )
}
