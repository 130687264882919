import { AddCircleOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import ContactForm from '@/features/customer/components/create/ContactForm'
import type { Site } from '@/features/customer/types/site'

function ContactsStep() {
  const { t } = useTranslation()

  const { control, setValue } = useFormContext<Site>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  })

  return (
    <Box>
      {fields.map((field, index) => (
        <ContactForm key={field.id} index={index} remove={remove} setValue={setValue} />
      ))}
      <CustomButton
        startIcon={<AddCircleOutlined />}
        onClick={() => {
          append({ name: '' })
        }}
      >
        {t('sites.add_new.form.contacts.add_contact')}
      </CustomButton>
    </Box>
  )
}

export default ContactsStep
