import type { FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { TextFieldControllerProps } from '@/components/inputs/TextFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { EMAIL_REGEX } from '@/utils/email'

export type EmailFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = TextFieldControllerProps<TFieldValues, TName> & {
  isEmailEnabled: boolean
}

export default function EmailFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ isEmailEnabled, ...props }: Readonly<EmailFieldControllerProps<TFieldValues, TName>>) {
  const { t } = useTranslation()

  return (
    <TextFieldController
      {...props}
      controllerProps={{
        rules: {
          validate: (value) => {
            if (!value && isEmailEnabled) {
              return t('customer_settings.form.notification_preferences.email_required_error').toString()
            }
          },
          pattern: {
            value: EMAIL_REGEX,
            message: t('customer_settings.form.notification_preferences.email_invalid_error').toString(),
          },
        },
      }}
    />
  )
}
